import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time-input-calculator"
export default class extends Controller {
  static targets = [
    "startTime",
    "endTime",
    "duration",
  ]

  connect() {
  }

  calculateDuration() {
    if (!this.startTimeTarget.value || !this.endTimeTarget.value) {
      return
    }

    let startTimeParts = this.startTimeTarget.value.split(":").map(e => parseInt(e));
    let endTimeParts = this.endTimeTarget.value.split(":").map(e => parseInt(e));

    if (endTimeParts[0] < startTimeParts[0]) { // start hour is after end hour
      return
    } else if (endTimeParts[0] == startTimeParts[0] &&  endTimeParts[1] < startTimeParts[1] ) { // same hour and start minutes are after end minutes
      return
    }

    let totalMinutes = ((endTimeParts[0] - startTimeParts[0]) * 60) + endTimeParts[1] - startTimeParts[1];

    let totalHours = totalMinutes / 60.0;

    this.durationTarget.value = Math.ceil(totalHours * 4) / 4;
  }
}
